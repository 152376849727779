import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, computed, inject } from '@angular/core';
import {
  IntegratedPaginationParams,
  Paginated,
  PaginatedTableResponse,
} from '@models/paginated.model';
import { SnackbarService } from '@services/snackbar.service';
import {
  RefreshSignalType,
  RefreshSubjectEntity,
  RefreshSubjectType,
  TriggerEntitiesService,
} from '@services/trigger-entities.service';
import { mapPaginatedResponse } from '@utils/rxjs-operators';
import { Observable } from 'rxjs';

export interface ArrayOfItemsApiResponse<T> {
  items: T[];
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export abstract class CoreApiService {
  protected readonly http = inject(HttpClient);
  protected readonly document = inject(DOCUMENT);
  protected readonly triggerEntitiesService = inject(TriggerEntitiesService);
  protected readonly snackbarService = inject(SnackbarService);
  protected abstract entityName: RefreshSubjectEntity;

  getPage<T>(
    url: string,
    params: IntegratedPaginationParams,
  ): Observable<PaginatedTableResponse<T>> {
    return this.http
      .get<Paginated<T>>(url, {
        params: { ...params },
      })
      .pipe(mapPaginatedResponse());
  }

  triggerRefresh(entityName: RefreshSubjectEntity = this.entityName): void {
    this.triggerEntitiesService
      .getRefreshSignalFromEntity(entityName)
      .set(Date.now());
  }

  getRefreshSubject(): RefreshSubjectType {
    return this.triggerEntitiesService.getRefreshSubjectFromEntity(
      this.entityName,
    );
  }

  getRefreshSignal(): RefreshSignalType {
    return computed(() =>
      this.triggerEntitiesService.getRefreshSignalFromEntity(this.entityName)(),
    );
  }

  /**
   * Export Blob data to CSV, it creates an html element <a>, puts the url and the file name
   * inside and simulates a click, after it finishes the html elment <a> is removed.
   *
   * @param data Blob data
   * @param name the desired name of the file
   * @param mimeType Optional - the mime type
   */
  exportBlobToCSV(
    data: Blob | string | null,
    name: string,
    mimeType?: string,
  ): void {
    if (!data) return;
    const blob = new Blob([data], {
      type: mimeType || 'text/csv;charset=utf-8;',
    });
    const a = this.document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = name;
    a.click();
    a.remove();
  }

  protected generateHttpParams(params: Record<string, string>): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      httpParams = httpParams.set(key, params[key]);
    });
    return httpParams;
  }
}
