import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

export enum RefreshSubjectEntity {
  Analytics = 'analytics',
  Applications = 'applications',
  Areas = 'areas',
  Auth = 'auth',
  Boards = 'boards',
  Campaigns = 'campaigns',
  ClimateRecords = 'climate_records',
  ClimateStations = 'climate_stations',
  Company = 'company',
  Corporate = 'corporate',
  Countries = 'countries',
  Crops = 'crops',
  Geojson = 'geojson',
  HighFrequency = 'high_frequency',
  Labels = 'labels',
  Legend = 'legend',
  Lots = 'lots',
  Marker = 'marker',
  Me = 'me',
  Notes = 'notes',
  Operations = 'operations',
  PlotComparison = 'plot_comparison',
  Plots = 'plots',
  Products = 'products',
  Reports = 'reports',
  Roles = 'roles',
  Shortcuts = 'shortcuts',
  SmartSampling = 'smart_sampling',
  Tasks = 'tasks',
  Tickets = 'tickets',
  User = 'user',
  UserDownloads = 'user_downloads',
  VariableMaps = 'variable_maps',
}
export type RefreshSubjectType = Observable<number>;
export type RefreshWritableSignalType = WritableSignal<number>;
export type RefreshSignalType = Signal<number>;
export type RefreshByEntityType = Record<
  RefreshSubjectEntity,
  RefreshWritableSignalType
>;

@Injectable({
  providedIn: 'root',
})
export class TriggerEntitiesService {
  private readonly refreshSignalByEntity: RefreshByEntityType = Object.values(
    RefreshSubjectEntity,
  ).reduce(
    (acc, value) => ({
      ...acc,
      [value]: signal(Date.now()),
    }),
    {},
  ) as RefreshByEntityType;

  private readonly refreshSubjectByEntity = Object.entries(
    this.refreshSignalByEntity,
  ).reduce(
    (acc, value) => ({ ...acc, [value[0]]: toObservable(value[1]) }),
    {},
  ) as Record<RefreshSubjectEntity, Observable<number>>;

  getRefreshSubjectFromEntity(
    entity: RefreshSubjectEntity,
  ): RefreshSubjectType {
    return this.refreshSubjectByEntity[entity];
  }

  getRefreshSignalFromEntity(
    entity: RefreshSubjectEntity,
  ): RefreshWritableSignalType {
    return this.refreshSignalByEntity[entity];
  }

  triggerRefreshAll(): void {
    const keys = Object.values(RefreshSubjectEntity);
    keys.forEach((key) => this.getRefreshSignalFromEntity(key).set(Date.now()));
  }
}
